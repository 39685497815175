<template>
  <v-dialog v-model="oohConfig.showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  > 
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$parent.modalFindAndReplace(null, false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>OOH Submissons Find and Replace</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="$parent.modalFindAndReplace(null, false)">
            Close
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container class="py-4">
        <v-form ref="modalForm" @submit.prevent>
          <v-row>
            <v-img max-height="300" max-width="300" contain class="mr-4 mb-4" :src="getOOHImageUrl()" />
            <v-text-field 
              @keyup.enter.native="searchDataFromDB()"
              label="Enter Something to Lookup Predefined Database" 
              v-model="searchText"
              required
              hint="Search &quot;100plus&quot; &quot;berry&quot; (with the double quotes) to get result that fullfil both conditions"
              persistent-hint
              :rules="[ value => !!value || 'Field is required.' ]"
            />
            <v-btn @click="searchDataFromDB()" color="primary">Search</v-btn>
          </v-row>
        </v-form>

        <v-simple-table dense v-if="modalData && modalData.length > 0">
          <thead>
            <tr>
              <th v-for="(key, k_id) in Object.keys(modalData[0])" :key="`row_${k_id}`">
                {{ key != "_id" ? key : "" }}
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i_id) in modalData" :key="`item_${i_id}`">
              <td v-for="(key, k_id) in Object.keys(item)" :key="`item_${i_id}_key_${k_id}`">
                {{ key != "_id" ? item[key] : "" }}
              </td>
              <td><v-btn color="error" @click="processFindAndReplace(item, true)" x-small>Replace and Save</v-btn></td>
            </tr>
          </tbody>
        </v-simple-table>
        <center v-else>No Data is available at the moment.</center>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();

export default {
  data() {
    return {
      searchText: null,
      modalData: [],
    }
  },
  props: {
    modalUAData: Object,
    oohConfig: Object,
  },
  methods: {
    getOOHImageUrl() {
      if (this.modalUAData) {
        let q = this.modalUAData.data.filter(q => q.title == this.oohConfig.dialogType)
        
        if (q.length == 1 && q[0].answer) {
          return q[0].answer
        }
      }
    },
    processFindAndReplace(selected) {
      for (let row of this.modalUAData.data) {
        for (let key of Object.keys(selected)) {
          if (row.title.toUpperCase() == key.toUpperCase()) {
            row.answer = selected[key]
          }
        }
      }

      this.$parent.updateUserActivityData(this.modalUAData)
      this.$parent.modalFindAndReplace(null, false)
    },
    searchDataFromDB() {
      if (this.$refs.modalForm.validate()) {
        this.$setLoader()

        if (this.oohConfig.dialogType == "STORE IMAGE") {
          service.findOohStoreData({ text: this.searchText }).then(data => {
            this.modalData = data.data
            this.$disableLoader()
          })
        } else if (this.oohConfig.dialogType == "PRODUCT (RTD BEVERAGE)") {
          service.findOohBrandData({ text: this.searchText }).then(data => {
            this.modalData = data.data
            this.$disableLoader()
          })
        }
      }
    },
  }
}
</script>