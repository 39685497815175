import { render, staticRenderFns } from "./oohDialog.vue?vue&type=template&id=16e67b07"
import script from "./oohDialog.vue?vue&type=script&lang=js"
export * from "./oohDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports